import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueI18n from 'vue-i18n'

import i18nBus from './bus/i18n.js'

Vue.config.productionTip = false

Vue.use(VueI18n)

async function setupApp() {
  const i18n = await i18nBus.setup()

  new Vue({
    router,
    render: h => h(App),
    i18n
  }).$mount('#app')
}

setupApp()