import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path: '/download',
    name: 'Download',
    component: () => import('../views/Download.vue')
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: () => import('../views/Pricing.vue')
  },
  {
    path: '/service',
    name: 'Service',
    component: () => import('../views/Service.vue')
  },
  {
    path: '/buy',
    name: 'Buy',
    component: () => import('../views/Buy.vue')
  },
  {
    path: '/buy/success',
    name: 'BuySuccess',
    component: () => import('../views/BuySuccess.vue')
  },
  {
    path: '/learn',
    name: 'Learn',
    component: () => import('../views/Learn.vue')
  },
  {
    path: '/docs',
    name: 'Docs',
    component: () => import('../views/Docs.vue')
  },
  {
    path: '/docs/:category/:page?', // '/docs/:category/:subcate?/:page?'
    name: 'ReadDocs',
    component: () => import('../views/Docs.vue')
  },
  {
    path: '/release/:type',
    name: 'Release',
    component: () => import('../views/Release.vue')
  },
  {
    path: '*',
    name: '404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash && to.name != 'Docs' && to.name != 'ReadDocs') {
      return {
        selector: to.hash
      }
    }
    
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  if (to.name == '404' || (to.name == 'ReadDocs' && to.params.page && to.params.page == 'index.html')) {
    if(to.path=="/release_notes.html")
      next('/release/editor');
    else if(to.path=='/buy.html')
      next('/buy');
    else if(to.name=='ReadDocs')
      next('/docs/editor')
    else
      next('/')
  } else if (to.path == '/docs') {
    next('/docs/editor')
  } else if (to.path == '/release') {
    next('/release/editor')
  } else {
    next()
  }
})

export default router
