import VueI18n from 'vue-i18n'
// import messages from '@/lang/zh.json'

class I18nController {

    constructor() {
        this.i18n = null
        this.loadedLanguages = []
    }

    async setup() {
        const savedLang = localStorage.getItem('fairy-lang')
        let lang = ''
        if (savedLang) {
            lang = savedLang
        }

        const locale = lang || (navigator.language || navigator.userLanguage).toLowerCase().split('-')[0]
        const langCode = locale == 'zh' ? 'zh' : 'en'

        this.i18n = new VueI18n({
            locale: langCode,
            // fallbackLocale: 'zh',
            messages: (await import('@/lang/' + langCode + '.json')).default,
        })

        this.loadedLanguages.push(langCode)

        return this.i18n
    }

    async loadLanguageAsync(lang) {
        if (this.i18n.locale !== lang) {
            if (!this.loadedLanguages.includes(lang)) {
                const msgs = await import(/* webpackChunkName: "lang-[request]" */ `@/lang/${lang}.json`)
                this.i18n.setLocaleMessage(lang, msgs.default[lang])
                this.i18n.locale = lang
                this.loadedLanguages.push(lang)
                localStorage.setItem("fairy-lang", lang)
                return
            }
            this.i18n.locale = lang
            localStorage.setItem("fairy-lang", lang)
        }
    }

}

export default new I18nController()