<template>
    <footer class="g">
        <div class="top">
            <div class="container">
                <div class="footer-item">
                    <h2>{{ $t('layout.footer.community.title') }}</h2>
                    <p class="info">{{ $t('layout.footer.community.content') }}</p>
                </div>
                <div class="footer-item">
                    <h2>{{ $t('layout.footer.use_fairy.title') }}</h2>
                    <p class="info">
                        <router-link to="/download#download-editor">{{ $t('layout.footer.use_fairy.download_editor') }}</router-link><br/>
                        <router-link to="/download#download-sdk">{{ $t('layout.footer.use_fairy.download_sdk') }}</router-link><br/>
                        <router-link to="/docs">{{ $t('layout.footer.use_fairy.docs') }}</router-link><br/>
                        <a href="http://www.fairygui.com/api/" target="_blank" rel="noopener noreferrer">{{ $t('layout.footer.use_fairy.api') }}</a>
                    </p>
                </div>
                <div class="footer-item">
                    <h2>{{ $t('layout.footer.contact.title') }}</h2>
                    <p class="info">{{ $t('layout.footer.contact.email') }}
                        <img draggable="false" src="@/assets/social/woa-qrcode.jpg" alt="">
                    </p>
                </div>
            </div>
        </div>
        <div class="bottom">
            <div class="container">
                <div class="left">
                    <p>{{ $t('layout.footer.copy') }}<br/>This site is designed & developed by <a href="https://dring.works/" target="_blank" rel="noopener noreferrer">DRING</a>.</p>
                </div>
                <div class="right">
                    <p>
                        <a href="http://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">粤ICP备15041112号</a><br>
                        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44011502000278" target="_blank" rel="noopener noreferrer">粤公网安备 44011502000278号</a>
                    </p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'SiteFooter'
}
</script>

<style lang="scss" scoped>
footer.g {
    background: #eee;

    .top {
        .container {
            display: flex;
            padding-top: 50px;
            padding-bottom: 50px;

            @media (max-width: 768px) {
                flex-wrap: wrap;
            }
        }

        .footer-item {
            width: 25%;
            flex-grow: 1;

            h2 {
                font-size: 24px;
            }
            .info {
                font-size: 14px;
                line-height: 1.9;
                color: rgb(121, 121, 121);
                white-space: pre-wrap;

                a {
                    color: #000;
                    text-decoration: none;
                    font-weight: bold;

                    &:hover {
                        text-decoration: underline;
                    }
                }
                img {
                    margin-top: .7em;
                    max-width: 100px;
                    display: block;
                }
            }
            @media (max-width: 768px) {
                width: 50%;
                margin-bottom: 35px;

                h2 {
                    font-size: 21px;
                    margin-top: 0;
                    margin-bottom: 10px;
                }
            }
            @media (max-width: 576px) {
                width: 100%;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .bottom {
        background: #E5E5E5;
        font-size: 12px;
        line-height: 1.5;
        padding: 25px 0;

        .container {
            display: flex;
        }
        p {
            margin: 0;

            a {
                color: #000;

                &:hover {
                    opacity: .7;
                }
            }
        }
        .left {
            width: 75%;
        }
        .right {
            width: 25%;
        }
        @media (max-width: 576px) {
            .container {
                flex-wrap: wrap;
            }

            .left {
                margin-bottom: 25px;
            }
            .left, .right {
                width: 100%;
            }
        }
    }
}
</style>