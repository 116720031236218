<template>
    <header :class="[ 'g', openMenu ? 'open' : '' ]">
        <div class="container">
            <router-link @click.native="closeMenu" class="logo-link" to="/">
                <img class="logo" draggable="false" src="@/assets/logo/brand-logo-full.svg" alt="FairyGUI">
            </router-link>
            <div class="links">
                <router-link @click.native="closeMenu" class="nav-link" to="/download">{{ $t('layout.header.download') }}</router-link>
                <router-link @click.native="closeMenu" class="nav-link" to="/pricing">{{ $t('layout.header.pricing') }}</router-link>
                <router-link @click.native="closeMenu" class="nav-link" to="/service">{{ $t('layout.header.service') }}</router-link>
                <router-link @click.native="closeMenu" class="nav-link" to="/learn">{{ $t('layout.header.learn') }}</router-link>
                 <a href="https://layabox.com" class="nav-link" target="_blank">{{ $t('layout.header.layabox') }}</a>
                <div class="divider"></div>
                <a @click="switchLang" href="javascript:void(0)">{{ $i18n.locale == 'zh' ? 'English' : '中文' }}</a>
            </div>
            <div :class="[ 'menu-btn', openMenu ? 'open' : '' ]" @click="toggleMenu">
                <i class="iconfont">{{ openMenu ? '&#xe967;' : '&#xe607;' }}</i>
            </div>
        </div>
    </header>
</template>

<script>
import i18nBus from '../../bus/i18n.js'

export default {
    name: 'SiteHeader',
    data: () => {
        return {
            openMenu: false,
        }
    },
    methods: {
        toggleMenu() {
            window.scrollTo(0, 0)
            this.openMenu = !this.openMenu
            if (this.openMenu) {
                document.body.classList.add('mobile-lock-scroll')
            } else {
                document.body.classList.remove('mobile-lock-scroll')
            }
        },
        closeMenu() {
            this.openMenu = false
            document.body.classList.remove('mobile-lock-scroll')
        },
        async switchLang() {
            await i18nBus.loadLanguageAsync(this.$i18n.locale == 'zh' ? 'en' : 'zh')
            document.title = this.$te(`meta.${ this.$route.name }.title`, this.$i18n.locale) ? this.$t(`meta.${ this.$route.name }.title`) : this.$t('meta.default.title')
            document.querySelector('meta[name="description"]').content =  this.$te(`meta.${ this.$route.name }.description`, this.$i18n.locale) ? this.$t(`meta.${ this.$route.name }.description`) : this.$t('meta.default.description')
            this.closeMenu()
        }
    }
}
</script>

<style lang="scss" scoped>
header.g {
    height: 70px;
    width: 100%;
    background: #FFF8EA;
    color: #fff;
    overflow: hidden;
    position: relative;
    // z-index: 200;

    @media (max-width: 768px) {
        transition: height .5s;

        &.open {
            height: 100vh;
        }
    }
    .container {
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;

        @media (max-width: 768px) {
            height: 100vh;
            flex-direction: column;
            align-items: start;
            width: 100%;

            .logo-link {
                display: inline-flex;
                height: 70px;
                align-items: center;
            }
        }
    }
    .menu-btn {
        display: none;

        @media (max-width: 768px) {
            display: flex;
            position: absolute;
            right: 0;
            top: 0;
            width: 70px;
            height: 70px;
            align-items: center;
            justify-content: center;
            color: #000;
            transition: transform .5s;

            .iconfont {
                font-size: 28px;
            }
            &.open {
                transform: rotate(90deg);
            }
        }
    }
    a {
        line-height: 1;
    }
    .logo {
        height: 40px;
        display: block;

        &:hover {
            opacity: .7;
        }
        &:active {
            opacity: 1;
        }
    }
    .links {
        width: 1%;
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 100%;

        a {
            margin: 0 25px;
            padding: 0 10px;
            height: 100%;
            display: flex;
            align-items: center;
            font-weight: bold;
            text-decoration: none;
            color: #000;
            transition: color .3s;
            position: relative;
            
            &::after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                transition: border .3s;
                border-bottom: 0 solid transparent;
                box-sizing: border-box;
                display: none;
            }
            &.nav-link {
                &::after {
                    display: block;
                }
            }
            &:hover {
                color: rgba(#000, .7);
                
                &::after {
                    border-bottom: 4px solid rgba(#FFB116, .5);
                }
            }
            &.router-link-active {
                &::after {
                    border-bottom: 4px solid rgba(#FFB116, 1);
                }
            }
        }
        .divider {
            margin: 0 45px;
            height: 16px;
            border-right: 1px solid #CDA149;
        }
        @media (max-width: 768px) {
            width: 100%;
            height: 1%;
            flex-grow: 1;
            flex-direction: column;
            justify-content: center;

            a {
                height: auto;
                margin-bottom: 15px;
                padding-bottom: 15px;
                width: 100%;
                border-bottom: 2px solid rgba(#553310, .15);

                &:last-child {
                    border-bottom: none;
                }
                &.router-link-active {
                    border-bottom: 2px solid transparent;
                }
            }
            .divider {
                display: none;
            }
        }
    }
}
</style>