<template>
  <div class="home">
    <div class="top-hero">
      <div class="container">
        <img class="top-logo" draggable="false" src="@/assets/logo/brand-logo-full.svg" alt="FairyGUI">
        <h2>{{ $t('page.home.top.project_intro') }}</h2>
        <p>{{ $t('page.home.top.project_detail') }}</p>
        <div class="btn-group">
          <router-link class="big-btn" to="/download">{{ $t('page.home.common.free_to_start') }} <i class="iconfont">&#xe638;</i></router-link>
          <router-link class="big-btn ghost" to="/docs">{{ $t('page.home.top.tutorial') }}</router-link>
        </div>
        <div class="screenshot">
          <img draggable="false" src="../assets/decoration/screenshot.png" alt="截图">
        </div>
        <div class="bottom-gradient"></div>
      </div>
    </div>
    <div class="container">

      <div class="why-fairy">
        <div class="row">
          <div class="why-card">
            <div class="info">
              <h1>{{ $t('page.home.why_fairy.zero_code_title') }}</h1>
              <p>{{ $t('page.home.why_fairy.zero_code_content') }}</p>
              <div class="btn-sec">
                <router-link class="big-btn" to="/download#download-editor">{{ $t('page.home.why_fairy.download_editor') }}</router-link>
              </div>
            </div>
            <div class="figure">
              <img class="zero-code-figure" draggable="false" src="@/assets/decoration/zero-code.svg" alt="">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="why-card why-language">
            <div class="info">
              <h1>{{ $t('page.home.why_fairy.i18n_title') }}</h1>
              <p>{{ $t('page.home.why_fairy.i18n_content') }}</p>
              <div class="btn-sec right">
                <router-link class="big-btn" to="/docs/editor/branch">{{ $t('page.home.why_fairy.learn_branch') }}</router-link>
              </div>
            </div>
          </div>
          <div class="why-card why-fast">
            <div class="info">
              <h1>{{ $t('page.home.why_fairy.fast_title') }}</h1>
              <p>{{ $t('page.home.why_fairy.fast_content') }}</p>
              <div class="btn-sec right">
                <router-link class="big-btn" to="/docs/unity/drawcall">{{ $t('page.home.why_fairy.learn_optimization') }}</router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="why-card">
            <div class="info">
              <h1>{{ $t('page.home.why_fairy.rich_runtime_title') }}</h1>
              <p>{{ $t('page.home.why_fairy.rich_runtime_content') }}</p>
              <div class="btn-sec">
                <router-link class="big-btn" to="/download#download-sdk">{{ $t('page.home.why_fairy.download_sdk') }}</router-link>
              </div>
            </div>
            <div class="figure engine">
              <img class="engine-figure" draggable="false" src="@/assets/decoration/engine.svg" alt="">
            </div>
          </div>
        </div>
      </div>

      <div class="try-fairy">
        <h1>{{ $t('page.home.try_fairy.title') }}</h1>
        <router-link class="big-btn" to="/download">{{ $t('page.home.common.free_to_start') }} <i class="iconfont">&#xe638;</i></router-link>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
  }
}
</script>

<style lang="scss" scoped>
.top-hero {
  padding-top: 56px;
  background: url(../assets/decoration/pop-white.svg) no-repeat;
  background-size: 1382px auto;
  background-position: center center;
  position: relative;

  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .top-logo {
    width: 65%;
    max-width: 280px;
  }
  h2 {
    font-size: 27px;
    text-align: center;
    margin-top: 44px;
    margin-bottom: 0;
  }
  p {
    font-size: 18px;
    line-height: 28px;
    font-weight: bold;
    color: #909090;
    text-align: center;
    margin: 0;
    margin-top: 10px;
    white-space: pre-wrap;
  }
  .btn-group {
    margin-top: 44px;
    margin-bottom: 60px;

    .big-btn {
      margin: 0 8px;
    }
    @media (max-width: 576px) {
      text-align: center;
      
      .big-btn {
        margin-bottom: 10px;
      }
    }
  }
  .screenshot {
    img {
      width: 100%;
      max-width: 635px;
      border-radius: 4px 4px 0 0;
    }
  }
  .bottom-gradient {
    position: absolute;
    width: 100%;
    height: 70px;
    left: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  }
}

.why-fairy {
  margin-top: 50px;

  .row {
    display: flex;
    margin-bottom: 12px;

    .why-card {
      width: 50%;
      flex-grow: 1;
      background: #F1F1F1;
      border-radius: 16px;
      margin-right: 12px;
      display: flex;
      overflow: hidden;

      &:last-child {
        margin-right: 0;
      }
      .info {
        width: 50%;
        flex-grow: 1;
        padding: 60px 30px;
        display: flex;
        flex-direction: column;

        h1 {
          margin: 0;
          margin-bottom: 44px;
          line-height: 1.15;
          font-size: calc(20px + 2.6vw);
          color: var(--p);
          white-space: pre-wrap;
        }
        p {
          max-width: 50%;
          line-height: 30px;
          font-weight: bold;
          margin: 0;
          margin-bottom: 44px;
          height: 1%;
          flex-grow: 1;
        }
        .btn-sec {
          height: 60px;
          display: flex;
          align-items: center;

          &.right {
            justify-content: flex-end;
          }
        }
      }
      .figure {
        width: 50%;
        flex-grow: 1;

        .zero-code-figure {
          width: 100%;
          height: 100%;
          transform: translate(20px, 50px);
        }
        &.engine {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 35px;
          padding-left: 0;

          img {
            width: 100%;
          }
        }
      }
      &.why-language {
        background-image: url(../assets/decoration/chat-bubble.svg);
        background-repeat: no-repeat;
        background-size: auto 63%;
        background-position: bottom -20% right -100px;
      }
      &.why-fast {
        background-image: url(../assets/decoration/flash.svg);
        background-repeat: no-repeat;
        background-size: auto 80%;
        background-position: bottom -50% right -60px;
      }
    }
    @media (max-width: 768px) {
      flex-wrap: wrap;
      margin-bottom: 0;
      
      .why-card {
        width: 100%;
        margin-bottom: 12px;
        margin-right: 0;
      }
    }
    @media (max-width: 576px) {
      .why-card {
        flex-direction: column-reverse;

        .info, .figure {
          width: 100%;
        }
        .info {
          padding-top: 40px;
          padding-bottom: 40px;

          p {
            max-width: 100%;
            margin-bottom: 30px;
          }
          .btn-sec {
            justify-content: flex-end;
          }
          h1 {
            margin-bottom: 30px;
          }
        }
        .figure {
          padding: 30px 50px 20px;
          background: #e5e5e5;

          .zero-code-figure {
            transform: none;
            width: 100%;
          }
          &.engine {
            padding: 0;
          }
        }
      }
    }
  }
}

.try-fairy {
  text-align: center;
  padding: 128px 0;
  
  h1 {
    font-size: 48px;
    margin: 0;
    margin-bottom: 24px;

    @media (max-width: 576px) {
      font-size: 36px;
    }
  }
}
</style>