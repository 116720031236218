<template>
  <div id="app">
    <site-header/>
    <router-view :key="$i18n.locale" />
    <site-footer/>
  </div>
</template>

<script>
import SiteHeader from './components/layout/SiteHeader.vue'
import SiteFooter from './components/layout/SiteFooter.vue'

export default {
  name: 'App',
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if (to.name != null && to.name != 'ReadDocs') {
          document.title = this.$te(`meta.${ to.name }.title`, this.$i18n.locale) ? this.$t(`meta.${ to.name }.title`) : this.$t('meta.default.title')
          document.querySelector('meta[name="description"]').content =  this.$te(`meta.${ to.name }.description`, this.$i18n.locale) ? this.$t(`meta.${ to.name }.description`) : this.$t('meta.default.description')
        }
      }
    }
  },
  components: {
    SiteHeader,
    SiteFooter,
  }
}
</script>

<style lang="scss">
//@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@font-face {
  font-family: 'iconfont';  /* project id 2250858 */
  src: url('//at.alicdn.com/t/font_2250858_o6ztmkq7lvd.eot');
  src: url('//at.alicdn.com/t/font_2250858_o6ztmkq7lvd.eot?#iefix') format('embedded-opentype'),
  url('//at.alicdn.com/t/font_2250858_o6ztmkq7lvd.woff2') format('woff2'),
  url('//at.alicdn.com/t/font_2250858_o6ztmkq7lvd.woff') format('woff'),
  url('//at.alicdn.com/t/font_2250858_o6ztmkq7lvd.ttf') format('truetype'),
  url('//at.alicdn.com/t/font_2250858_o6ztmkq7lvd.svg#iconfont') format('svg');
}

.iconfont{
    font-family:"iconfont" !important;
    font-size:16px;font-style:normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  background: #E5E5E5;
  color: #000;

  &.mobile-lock-scroll {
    @media (max-width: 768px) {
      overflow: hidden;
    }
  }
}

* {
  box-sizing: border-box;
}

#app {
  font-family: 'Poppins', -apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Arial,PingFang SC,Hiragino Sans GB,Microsoft YaHei, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;

  --p: #FF971A;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.container {
  margin: 0 auto;
  width: 100%;
  padding: 0 20px;

  @media (min-width: 860px) {
    padding: 0 30px;
  }
  @media (min-width: 1024px) {
    padding: 0 55px;
  }
  @media (min-width: 1366px) {
    max-width: 1210px;
  }
  @media (min-width: 1920px) {
    max-width: 1330px;
  }
}

.big-btn {
  padding: 12px 30px;
  border-radius: 999px;
  background: var(--p);
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;
  box-sizing: border-box;
  border: 3px solid var(--p);
  display: inline-block;

  &:hover {
    border-color: #ffae4b;
    background: #ffae4b;
  }
  &:active {
    border-color: #bb6f11;
    background: #bb6f11;
  }
  &.ghost {
    background: none;
    color: var(--p);
    &:hover {
      color: #ffae4b;
    }
    &:active {
      color: #bb6f11;
    }
  }
  .iconfont {
    font-weight: normal;
  }
}

.four-row {
  display: flex;
  width: calc(100% + 16px);
  margin-left: calc(-8px);

  .item {
    width: 25%;
    padding: 0 8px;
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;

    .item {
      width: 50%;
      margin-bottom: 35px;
    }
  }
  @media (max-width: 576px) {
    .item {
      width: 100%;
    }
  }
}
</style>
